<!-- 文件中文名: WV换货 -->
<template>
  <div class="index app-container">
    <div class="page-container">
      <h3 style="color: #b3640d">{{ $t('WvExchangeOrder') }}</h3>
      <p style="color:#538E0D; font-size:14px; font-weight:bold;">WV
        <span style="color:#F00; font-size:14px; font-weight:bold;">{{ WV }}</span></p>
      <div v-if="activeIndex === 2">
        <h3 style="color: #b3640d">{{ $t('mall.ProductsList') }}</h3>
        <el-table
            v-loading="doLoading"
            :data="list"
            border
            fit
            highlight-current-row
            max-height="600"
            size="mini"
            style="width: 100%;"
        >
          <!--     预览 -->
          <el-table-column :label="$t('button.preview')" align="center" prop="imgsrc" width="80px">
            <template v-slot="{ row }">
              <el-image
                  v-if="row.imgsrc"
                  :preview-src-list="row.imgsrc.split(';')"
                  :src="row.imgsrc.split(';')[0]"
                  fit="contain"
                  style="width: 100%; height: 100%"
              >
              </el-image>
            </template>
          </el-table-column>
          <!--      商品编码-->
          <el-table-column :label="$t('BiGoods.goodsCode')" align="center" prop="goodscode"/>
          <!--      商品名称-->
          <el-table-column :label="$t('BiGoods.name')" align="center" prop="name"/>
          <!--      价格-->
          <el-table-column :label="$t('pd.price')" align="center" prop="standardPrice"/>
          <!--      积分-->
          <el-table-column :label="$t('busi.direct.sale_points')" align="center" prop="standardPv"/>
          <!--      可用库存量-->
          <el-table-column :label="$t('stStock.validQty')" align="center" prop="validQty"/>
          <!--      购买数量-->
          <el-table-column :label="$t('Common.buyNumber')" align="center" prop="buyQty">
            <template v-slot="{row}">
              <el-input-number :min="0" @change="getSuiteNum(row)" size="small" v-model.number="row.buyQty"/>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="this.Promotion === true" >
          <el-table
              border
              :data="tableData1"
              header-align="center"
              style="width: 100%">
            <el-table-column prop="goodscode" align="center" :label="$t('BiGoods.goodsCode')"/>
            <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
            <el-table-column width="100" prop="standardPv" align="center" :label="$t('PV')"></el-table-column>
            <el-table-column width="100" prop="standardPrice" align="center" :label="$t('pd.price')"></el-table-column>
            <el-table-column :label="$t('biBarCode.quantity')" align="center" width="140">
              <template slot-scope="{ row }">
                <el-input-number
                    v-model.number="row.buyQty"
                    :min="0"
                    size="small"
                />
              </template>
            </el-table-column>
          </el-table>
          <el-table
              border
              :data="tableData2"
              header-align="center"
              style="width: 100%">
            <el-table-column prop="goodscode" align="center" :label="$t('BiGoods.goodsCode')"/>
            <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
            <el-table-column width="100" prop="standardPv" align="center" :label="$t('PV')"></el-table-column>
            <el-table-column width="100" prop="standardPrice" align="center" :label="$t('pd.price')"></el-table-column>
            <el-table-column :label="$t('biBarCode.quantity')" align="center" width="140">
              <template slot-scope="{ row }">
                <el-input-number
                    v-model.number="row.buyQty"
                    :min="0"
                    size="small"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <p style="color:#538E0D; font-size:14px; font-weight:bold;">
          <!--金额-->
          {{ $t('busi.finance.amount') }}
          <span :style="Number(totalfA) > Number(totalFv) ? 'color: #3e4f6a':'color:#F00'">{{ totalfA }}</span>
          <span v-show="Number(totalfA) > Number(totalFv)" style="color: #3e4f6a">(-{{ Number(totalFv).toFixed(2) }})=</span>
          <span v-show="Number(totalfA) > Number(totalFv)" style="color:#F00"> {{ (Number(totalfA) - Number(totalFv)).toFixed(2) }}</span>
          <span v-show="Number(totalfA) > Number(totalFv)" style="color:#F00"> ({{ $t('YourGoodsPriceNeedDeduct') }} {{ $t('DifferenceObtained') }})</span>
          {{ $t('busi.direct.sale_points') }} <span style="color:#F00">{{ totalFv }}</span></p>
        <div style="text-align: center; margin-bottom: 10px;margin-top: 10px">
          <!--      下一步-->
          <el-button :loading="doLoading" icon="el-icon-right" size="mini" type="success" @click="buttonnext2">
            {{ $t('button.next') }}
          </el-button>
        </div>
      </div>
      <div v-if="activeIndex === 3">
        <el-form label-width="auto">
          <!--          收货方式-->
          <h3 style="color: #b3640d">{{ $t('PoOrder.recType') }}</h3>
          <div>
            <el-form-item :label="$t('busi.JPorder.memberNO')+(':')">
              <span>{{ this.userCode }}</span>
            </el-form-item>
            <el-form-item :label="$t('PoOrder.recType')" required>
              <el-radio v-model="listQuery.recType" label="1" @change="setAgentAddr">
                <!--                代办处提货-->
                {{ this.$t('ru.please.goto.agent.toGet') }}
              </el-radio>
              <br>
              <el-radio v-if="companyCode !== 'LTV'" v-model="listQuery.recType" label="2" @change="setCompanyAddr">
                <!--                分公司提货-->
                {{ this.$t('pick.up.from.branch.office') }}
              </el-radio>
              <br v-if="companyCode !== 'LTV'">
              <!--                发到经销商-->
              <el-radio v-model="listQuery.recType" label="3" @change="setMemberAddr">
                {{ this.$t('GoodsToMember') }}
              </el-radio>
              <br>
            </el-form-item>
            <div v-if="listQuery.recType==='1'">
              <!--          代办处提货-->
              <el-form-item :label="$t('ru.please.goto.agent.toGet')" required size="mini" style="width: 100%">
                <el-radio-group v-model="listQuery.recNo" size="mini" style="width: 100%" value-key="agentNo">
                  <div
                      v-for="(item,index) in AgentGetGoodsList"
                      :key="item.agentNo"
                      :class="{ 'under-line': index===AgentGetGoodsList.length-1 }"
                      style="margin: 5px 0;width: 100%;border-top: 1px solid #ddd"
                      @click="agentRadioChange(item)"
                  >
                    <el-radio :label="item.agentNo" class="AgentRadio" style="height: auto;overflow: hidden;">
                      <div style="display: inline-block">
                        <p style="display: block;white-space: pre-wrap;word-break:break-word;overflow: hidden;padding-right: 80px">
                          {{ item.companyCode }}&nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;&nbsp;({{ item.agentNo }})&nbsp;&nbsp;&nbsp;
                          {{ item.mobile }}
                        </p>
                        <p style="display: block;white-space: pre-wrap;word-break:break-word;overflow: hidden;padding-right: 80px">
                          {{ item.storeAddr }}</p>
                      </div>
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
            </div>
            <div v-if="listQuery.recType==='2'">
              <!--          从分公司提货-->
              <el-form-item :label="$t('pick.up.from.branch.office')" required size="mini" style="width: 100%">
                <el-radio-group v-model="listQuery.outCompanycode" size="mini" style="width: 100%" value-key="agentNo">
                  <div
                      v-for="(item,index) in CompanyGetGoodsList"
                      :key="item.City"
                      :class="{ 'under-line': index===CompanyGetGoodsList.length-1 }"
                      style="margin: 5px 0;width: 100%;border-top: 1px solid #ddd"
                      @click="outCompanyCodeRadioChange(item)"
                  >
                    <el-radio :label="item.companyCode" class="AgentRadio" style="height: auto;overflow: hidden;">
                      <div style="display: inline-block">
                        <p>{{ $t(item.companyCode) }}-{{ $t(item.Name) }}</p>
                        <p>{{ $t(item.Addr) }}</p>
                      </div>
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
            </div>
            <div v-if="listQuery.recType==='3'">
              <!--          地址-->
<!--              <el-form-item :label="$t('mi.address.id')" required size="mini">-->
<!--                <el-cascader-->
<!--                    v-model="regionCodeList" :props="props" filterable style="width: 100%" @change="handleChange"/>-->
<!--              </el-form-item>-->
              <el-form-item :label="$t('mi.address.id')" required>
                <el-select v-model="listQuery.countryCode" filterable @change="countryChange" popper-class="selectAddr">
                  <el-option
                      v-for="item in countryList"
                      :key="item.id"
                      :label="item.country"
                      :value="item.country"/>
                </el-select>
                <el-select v-model="listQuery.cityCode" filterable @change="cityChange" popper-class="selectAddr">
                  <el-option
                      v-for="item in cityList"
                      :key="item.id"
                      :label="item.province"
                      :value="item.id"/>
                </el-select>
              </el-form-item>
              <el-form-item v-show="getSendFree" :label="$t('shipping.cost')">
                {{ getSendFree }}
              </el-form-item>
              <!--          详细地址-->
              <el-form-item :label="$t('miAgent.storeAddr')" required size="mini">
                <el-input v-model="listQuery.storeAddr"/>
              </el-form-item>
              <el-form-item :label="$t('busi.JPorder.receiverName')+(':')" required size="mini">
                <el-input v-model="listQuery.name"/>
              </el-form-item>
              <el-form-item :label="$t('fiBankbookTempRecharge.mobile')+(':')" required size="mini">
                <el-input v-model="listQuery.mobile"/>
              </el-form-item>
              <el-form-item :label="$t('elektronik.wallet.russia')">
                <span>{{ F$ }}</span>
              </el-form-item>
              <!--货币兑换-->
              <el-form-item v-if="companyCode !== 'LTV'" :label="$t('currency.exchange')+':'">
                <!--本位币钱包-->
                <span> {{ $t('Base.currency.wallet') }}({{ $t('pmEfuboCard.balance') }} : {{ B$ }}B$) → </span>
                <span v-show="fRate" style="color: red">{{ rate }}W$ </span>
                <span v-show="fRate">{{ $t('alCurrency.rate') }}={{ fRate }}:1 </span>
                <el-input style="width: 200px; text-align: center" type="number" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="currencyInfo.money" @blur="getRate($event.target.value)">
                  <template slot="prepend">B$</template>
                </el-input>
                <el-popover placement="bottom-end" width="200" trigger="click" v-model="popoverVisible">
                  <p>{{ $t('fi.sure.handle') }}</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="popoverVisible = false">{{ $t('operation.button.cancel')}}</el-button>
                    <el-button type="primary" size="mini" @click="exchangeBtn">{{ $t('button.confirm') }}</el-button>
                  </div>
                  <el-button :loading="doLoading" type="warning" plain slot="reference">{{$t('btn.implement')}}</el-button>
                </el-popover>
              </el-form-item>
            </div>
          </div>
          <!--          支付信息-->
          <h3 style="color: #b3640d">{{ $t('title.payment.info') }}</h3>
          <!--         商品总金额 -->
          <el-form-item :label="$t('poAssistMemberOrder.productAmount')">
            <!--{{ listQuery.totalAmount }}-->
            <p style="color:#538E0D; font-size:14px; font-weight:bold;margin: 0">
              <span :style="Number(totalfA) > Number(totalFv) ? 'color: #3e4f6a':'color:#F00'">{{ totalfA }}</span>
              <span v-show="Number(totalfA) > Number(totalFv)" style="color: #3e4f6a">(-{{ Number(totalFv).toFixed(2) }})=</span>
              <span v-show="Number(totalfA) > Number(totalFv)" style="color:#F00"> {{ (Number(totalfA) - Number(totalFv)).toFixed(2) }}</span>
              <span v-show="Number(totalfA) > Number(totalFv)" style="color:#F00"> ({{ $t('YourGoodsPriceNeedDeduct') }} {{ $t('DifferenceObtained') }})</span>
            </p>
          </el-form-item>
          <!--         积分 -->
          <el-form-item :label="$t('busi.direct.sale_points')">
            <span style="color:#538E0D; font-size:14px; font-weight:bold;">{{ totalFv }}</span>
          </el-form-item>
          <!--         支付密码 -->
          <el-form-item :label="$t('please.input.your.pay.password')"
                        :rules="{ required: true, message: this.$t('label.pleaseFillIn'), trigger: ['blur', 'change'] }"
                        size="mini"
                        style="width: 400px">
            <el-input v-model.trim="listQuery.payPassword" :placeholder="$t('')" clearable show-password/>
          </el-form-item>
        </el-form>
        <table>
          <thead>
          <tr>
            <th>{{ $t('BiGoods.goodsCode') }}</th>
            <th>{{ $t('BiGoods.name') }}</th>
            <th>{{ $t('pd.price') }}</th>
            <th>{{ $t('busi.direct.sale_points') }}</th>
            <th>{{ $t('Common.buyNumber') }}</th>
            <th>{{ $t('order.price.total') }}</th>
            <th>{{ $t('order.pv.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in list3" :key="i.id">
            <td>{{ i.goodscode }}</td>
            <td>{{ i.name }}</td>
            <td>{{ i.standardPrice }}</td>
            <td>{{ i.standardPv }}</td>
            <td>{{ i.buyQty }}</td>
            <td>{{ i.standardPrice * i.buyQty }}</td>
            <td>{{ i.standardPv * i.buyQty }}</td>
          </tr>
          <tr>
            <td>{{ $t('poOrder.amtCount') }}</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>{{ buyQty }}</td>
            <td>{{ totalfA }}</td>
            <td>{{ totalFv }}</td>
          </tr>
          </tbody>
        </table>
        <div style="text-align: center; margin-bottom: 10px;margin-top: 10px">
          <!--        上一步-->
          <el-button :loading="doLoading" icon="el-icon-back" size="mini" type="success" @click="buttonprevious3">
            {{ $t('button.previous') }}
          </el-button>
          <!--        立即支付-->
          <el-button :loading="doLoading" icon="el-icon-check" size="mini" type="success"
                     @click="titlesubmit(0)">{{ $t('immediately.payment') }}
          </el-button>
          <!--        稍后支付-->
          <!--<el-button :loading="doLoading" icon="el-icon-check" size="mini" type="primary"-->
          <!--           @click="titlesubmit(1)">{{ $t('btn.pay.later') }}-->
          <!--</el-button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {miMemberInfo2} from "@/api/userInfo/userInfo";
import {
  checkChaJia,
  commonGetMiAgents,
  commonGetRecAddrs, getExRateAndY, getPersonalData2, getRecAddrsWEU,
  goodsListSJByMember, saveOrUpdateFiAcEXChange, saveWvOrder
} from "@/api/rus/api";
export default {
  name: 'NewOrderWv',
  directives: {},
  components: {},
  data() {
    return {
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const {level} = node
          if (level === 0) {
            commonGetRecAddrs().then(res => {
              const nodes = res.data.map(item => ({
                value: item.country,
                label: `${item.country}`,
              }))
              resolve(nodes)
            }).catch(err => {
              console.warn(err)
            })
          } else {
            commonGetRecAddrs({country: node.value}).then(res => {
              const nodes = res.data.map(item => ({
                value: item.id,
                label: `${item.province}`,
                leaf: true
              }))
              resolve(nodes)
            }).catch(err => {
              console.warn(err)
            })
          }
        }
      },
      fRate: '',
      popoverVisible: false,
      F$: 0,
      B$: 0,
      rate: '',
      currencyInfo: {
        money: 0
      },
      regionCodeList: [],
      AgentGetGoodsList: [], // 提货代办处列表
      CompanyGetGoodsList: [], // 分公司提货列表
      userMemberInfoObj: null,
      AgentInfoObj: null,
      CompanyInfoObj: null,
      activeIndex: 2,
      listQuery: {
        countryCode: '',
        cityCode: '',
        outCompanycode: '',
        storeAddr: '',
        name: '',
        mobile: '',
        recNo: '',
        recType: '3'
      },
      list3: [],
      list: [],
      companycodeString: '',
      countryList: [],
      cityList: [],
      doLoading: false,
      country: null,
      province: null,
      city: null,
      shougouW$: 0,
      zongW$: 0,
      WV: 0,
      FVT003EQTY:0,
      tableData:[],
      initDict: {
        outCompanyList: 'out.company.list',
        sendfreeList: 'sendfree.weu'
      },
      tableData1:[ // 赠品信息
        {
          goodscode:'FH01-M-E',
          name:'Магнитный пояс (M)(воротник и наколенники в подарок)',
          price:0,
          id:-1,
          isGive:2,
          standardPv:0,
          standardPrice:0,
          buyQty:0,
          proPv:0,
          proPriceB:0,
          standardPriceB:0,
          proF000:0
        },{
          goodscode:'FH01-L-E',
          name:'Магнитный пояс (L)(воротник и наколенники в подарок)',
          price:0,
          id:-2,
          isGive:2,
          standardPv:0,
          standardPrice:0,
          buyQty:0,
          proPv:0,
          proPriceB:0,
          standardPriceB:0,
          proF000:0
        },{
          goodscode:'FH01-XL-E',
          name:'Магнитный пояс (XL)(воротник и наколенники в подарок)',
          price:0,
          id:-3,
          isGive:2,
          standardPv:0,
          standardPrice:0,
          buyQty:0,
          proPv:0,
          proPriceB:0,
          standardPriceB:0,
          proF000:0
        },{
          goodscode:'FH01-XXL-E',
          name:'Магнитный пояс (XXL)(воротник и наколенники в подарок)',
          price:0,
          id:-4,
          isGive:2,
          standardPv:0,
          standardPrice:0,
          buyQty:0,
          proPv:0,
          proPriceB:0,
          standardPriceB:0,
          proF000:0
        }
      ],
      tableData2:[ // 赠品信息
        {
          goodscode:'FH03-M-E',
          name:'НаколенникM',
          price:0,
          id:-5,
          isGive:2,
          standardPv:0,
          standardPrice:0,
          buyQty:0,
          proPv:0,
          proPriceB:0,
          standardPriceB:0,
          proF000:0
        },{
          goodscode:'FH03-L-E',
          name:'Наколенник L',
          price:0,
          id:-6,
          isGive:2,
          standardPv:0,
          standardPrice:0,
          buyQty:0,
          proPv:0,
          proPriceB:0,
          standardPriceB:0,
          proF000:0
        },{
          goodscode:'FH03-XL-E',
          name:'Наколенник XL',
          price:0,
          id:-7,
          isGive:2,
          standardPv:0,
          standardPrice:0,
          buyQty:0,
          proPv:0,
          proPriceB:0,
          standardPriceB:0,
          proF000:0
        }
      ],
      Promotion:false,
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'companyCode'
    ]),
    totalfA() {
      if (!this.list || !this.list.length) {
        return 0
      }
      return this.list.reduce((acc, cur) => {
        return cur.standardPrice * cur.buyQty + acc
      }, 0).toFixed(2)
    },
    totalFv() {
      if (!this.list || !this.list.length) {
        return 0
      }
      return this.list.reduce((acc, cur) => {
        return cur.standardPv * cur.buyQty + acc
      }, 0).toFixed(2)
    },
    buyQty() {
      if (!this.list || !this.list.length) {
        return 0
      }
      return this.list.reduce((acc, cur) => {
        return Number(cur.buyQty) + acc
      }, 0)
    },
    getSendFree() {
      if(this.listQuery.countryCode && this.companycodeString === 'TRA') {
          return this.totalfA >= 500 ? 0 : 2
      }
      if(this.listQuery.countryCode && this.companycodeString !== 'TRA') {
        return Number(this.$lt(this.initDict.sendfreeList, this.companycodeString)) || 60
      }
      return 0
    }
  },
  async created() {
    this.countryList = await this.getAddrList('')
    this.doLoading = true
    await commonGetMiAgents().then(res => {
      this.AgentGetGoodsList = res.data
    }).catch(err => {
      console.error(err)
    })
    await checkChaJia({'memberNo': this.userCode}).then(res => {
      this.shougouW$ = res.data.shougouW$ || 0
      this.zongW$ = res.data.zongW$ || 0
      this.WV = res.data.wv || 0
    }).catch(err => {
      console.error(err)
    })
    await miMemberInfo2({'code': this.userCode}).then(res => {
      this.userMemberInfoObj = res.data
    }).catch(err => {
      console.error(err)
    })
    await goodsListSJByMember({'memberNo': this.userCode}).then(res => {
      this.list = res.data
    }).catch(err => {
      console.error(err)
    })
    await this.getBalance()
    this.doLoading = false
  },
  mounted() {
    if (this.companyCode === 'LTV') {
      this.CompanyGetGoodsList = [{Name: 'LTV_Company_Name', Addr: 'LTV_Company_Addr', companyCode: 'LTV'}] // 立陶宛
    }
    if (this.companyCode === 'TRA') {
      this.CompanyGetGoodsList = [ {Name: 'TRA_Company_Name', Addr: 'TRA_Company_Addr', companyCode: 'TRA'} ] // 土耳其
    }
  },
  methods: {
    getSuiteNum(row){
      if(row.goodscode === 'FVT-003-E(T)' && row.buyQty > 0){
        this.Promotion = true
        this.FVT003EQTY = row.buyQty
      }
      if(row.goodscode === 'FVT-003-E(T)' && row.buyQty === 0){
        this.Promotion = false
        this.tableData1[0].buyQty = 0
        this.tableData1[1].buyQty = 0
        this.tableData1[2].buyQty = 0
        this.tableData1[3].buyQty = 0
        this.tableData2[0].buyQty = 0
        this.tableData2[1].buyQty = 0
        this.tableData2[2].buyQty = 0
        this.FVT003EQTY = 0
      }
    },
    handleChange(val) {
      this.listQuery.regionCode = val[1]
    },
    cityChange(val) {
      this.listQuery.regionCode = val
    },
    async countryChange(val) {
      this.companycodeString = ''
      this.listQuery.cityCode = ''
      this.listQuery.regionCode = ''
      this.cityList = await this.getAddrList(val)
      for (let a = 0; a < this.countryList.length; a++) {
        if (this.countryList[a].country === val) {
          return this.companycodeString = this.countryList[a].companycode
        }
      }
    },
    getAddrList(countryCode) {
      return new Promise((resolve, reject)=>{
        getRecAddrsWEU({country: countryCode}).then(res => {
          resolve(res.data)
        }).catch(err => {
          console.warn(err)
          reject(err)
        })
      })
    },
    outCompanyCodeRadioChange(val) {
      this.listQuery.outCompanycode = val.companyCode
      this.CompanyInfoObj = val
      this.listQuery.storeAddr = this.$t(val.Addr).toString()
      this.listQuery.name = ''
      this.listQuery.mobile = this.$t(val.Tel).toString()
      this.listQuery.recNo = ''
    },
    agentRadioChange(val) {
      this.listQuery.storeAddr = val.storeAddr
      this.listQuery.name = val.name
      this.listQuery.mobile = val.mobile
      this.listQuery.recNo = val.agentNo
      this.AgentInfoObj = val
    },
    setAgentAddr() {
      this.listQuery.storeAddr = this.AgentInfoObj ? this.AgentInfoObj.storeAddr : ''
      this.listQuery.name = this.AgentInfoObj ? this.AgentInfoObj.name : ''
      this.listQuery.mobile = this.AgentInfoObj ? this.AgentInfoObj.mobile : ''
      this.listQuery.recNo = this.AgentInfoObj ? this.AgentInfoObj.agentNo : ''
    },
    setCompanyAddr() {
      this.listQuery.outCompanycode = this.CompanyInfoObj ? this.CompanyInfoObj.companyCode : ''
      this.listQuery.storeAddr = this.CompanyInfoObj ? this.$t(this.CompanyInfoObj.Addr).toString() : ''
      this.listQuery.name = ''
      this.listQuery.mobile = this.CompanyInfoObj ? this.$t(this.CompanyInfoObj.Tel).toString() : ''
      this.listQuery.recNo = ''
    },
    setMemberAddr() {
      this.listQuery.name = this.userMemberInfoObj.accountName
      this.listQuery.mobile = this.userMemberInfoObj.mobile
      this.listQuery.storeAddr = this.userMemberInfoObj.storeAddr
      this.listQuery.recNo = ''
    },
    buttonnext2() {
      if(this.Promotion === true ){
        this.list3 = []
        if( (this.tableData1[0].buyQty + this.tableData1[1].buyQty + this.tableData1[2].buyQty + this.tableData1[3].buyQty === this.FVT003EQTY) &&
            (this.tableData2[0].buyQty + this.tableData2[1].buyQty + this.tableData2[2].buyQty === this.FVT003EQTY) ){
          this.tableData1.map(ele=>{
            if(ele.buyQty >0 ){
              this.list3.push(ele)
            }
          })
          this.tableData2.map(ele=>{
            if(ele.buyQty >0){
              this.list3.push(ele)
            }
          })
        }else{
          return this.$message.error(this.$t('The.quantity.of.products'))
        }
      }
      if (this.buyQty <= 0) {
        return this.$message.error(this.$t('leastInputANumber').toString())
      }
      if (Number(this.totalFv) > Number(this.WV)) {
        return this.$message.error(this.$t('product.limitepv.out').toString())
      }
      this.activeIndex = 3
      for (let a = 0; a < this.list.length; a++) {
        if (this.list[a].buyQty > 0) {
          this.list3.push(this.list[a])
        }
      }
      for (let i = 0; i < this.list3.length; i++) {
        if (this.list3[i].buyQty <= 0) {
          this.list3.splice(i, 1)
        }
        for (let j = i + 1; j < this.list3.length; j++) {
          if (this.list3[i] === this.list3[j]) {
            this.order.details.splice(j, 1)
            j--
          }
        }
      }
    },
    buttonprevious3() {
      this.list3 = []
      this.activeIndex = 2
    },
    titlesubmit(val) {
      if (!this.listQuery.payPassword) {
        return this.$message.error(this.$t('please.input.your.pay.password').toString())
      }
      if (!this.listQuery.recType) {
        return this.$message.error('请选择收货方式')
      }
      this.listQuery.memberNo = this.userCode
      this.listQuery.payType = val
      this.listQuery.goodsList = this.list3
      this.listQuery.goodsList.map(item => {
        item.quantity = item.buyQty
      })
      this.listQuery.orderType = 20
      this.doLoading = true
      this.$confirm(this.$t('fi.sure.handle').toString(), this.$t('common.tips').toString(), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('operation.button.cancel'),
        customClass: 'msgBox',
        type: 'warning'
      }).then(() => {
        this.doLoading = true
        saveWvOrder({...this.listQuery, isPc: 1}).then(() => {
          this.doLoading = false
          this.$message.success(this.$t('bdSendRegister.operaterSuccess').toString())
          this.$router.push({name: 'OrderList'})
        }).catch(err => {
          this.doLoading = false
          this.$message.error(this.$t('bdSendRegister.operaterFail').toString())
          console.error(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('operation.button.cancel').toString()
        })
      })
    },
    getRate(val){
      if (val > +this.B$) {
        this.currencyInfo.money = 0
        this.rate = 0
        return this.$notify({
          duration: 2000,
          title: '',
          message: this.$t('error.promotion.amount'),
          type: 'warning'
        })
      }
      getExRateAndY({ 'acType': 'F$' }).then(res => {
        this.fRate = res.data.rate
        this.rate = (val / res.data.rate).toFixed(2)
      })
    },
    // 货币兑换
    exchangeBtn() {
      const exchangeInfo = {
        acType: 'B$',
        toAcType: 'F$',
        money: this.currencyInfo.money
      }
      this.popoverVisible = false
      this.doLoading = true
      saveOrUpdateFiAcEXChange({ ...exchangeInfo }).then(() => {
        this.getBalance()
        this.$message.success(this.$t('currency.exchange') + this.$t('bdSendRegister.operaterSuccess'))
      }).catch(() => {
        this.$message.error(this.$t('bdSendRegister.operaterFail'))
        this.doLoading = false
      })
    },
    // 获取账户余额
    getBalance () {
      getPersonalData2().then(res => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].acType === 'B$') {
            this.B$ = res.data[i].validBalance
          }
          if (res.data[i].acType === 'F$') {
            this.F$ = res.data[i].validBalance
          }
        }
        this.doLoading = false
      }).catch(err => {
        this.doLoading = false
        console.error(err)
      })
    }
  }
}

</script>

<style lang="scss" scoped>
table {
  margin: 20px 0 20px 0;
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;

  tr {
    th {
      width: 150px;
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      border: 1px solid black;
    }

    td {
      width: 200px;
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      border: 1px solid black;
    }
  }

  thead {
    th {
      font-size: 18px;
      border-right: 1px solid black;
      width: 400px;
    }
  }

  tbody {
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      font-size: 14px;
      border: 1px solid black;
      width: 200px;
    }
  }
}

.account_name_css:after {
  content: 'X';
  color: #ff4949;
}

.disabled_remove_css {
  ::v-deep .el-input__inner {
    background-color: white;
    color: black;
  }
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
